export default class WebGL2DApp {
  vertex: string
  fragment: string
  // fragment = `
  //   precision highp float;

  //   uniform vec2 uResolution;
  //   uniform float uTimeFloat;

  //   float plot(vec2 st)
  //   {
  //     return smoothstep(0.02, 0., abs(st.y - st.x));
  //   }

  //   float expImpulse( float x, float k )
  //   {
  //       float h = k*x;
  //       return h*exp(1.0-h);
  //   }

  //   void main()
  //   {
  //     vec2 st = gl_FragCoord.xy/uResolution;

  //     float move = sin(uTimeFloat)/5.;

  //     float x = expImpulse(st.x, 2.75 + move);

  //     vec3 color = plot(vec2(x-move, st.y + 0.2)) * vec3(1., 0., 0.);

  //     vec3 color2 = plot(vec2(x-move, 1.-st.y)) * vec3(1., 0., 0.);


  //     gl_FragColor = vec4(color + color2, 1.0);
  //   }
  // `
  private gl: WebGLRenderingContext
  private program: WebGLProgram | null
  private aVertexPosition: number | null
  private uResolution: WebGLUniformLocation | null
  private uTimeFloat: WebGLUniformLocation | null
  private vertexBuffer: WebGLBuffer | null

  public loaded : boolean = false

  constructor(gl: WebGLRenderingContext, vertex: string, fragment: string) {
    this.gl = gl
    this.vertex = vertex
    this.fragment = fragment
    this.program = this.initShaderProgram()
    this.aVertexPosition = this.program ? this.gl.getAttribLocation(this.program, "aVertexPosition") : null
    this.uResolution = this.program ? this.gl.getUniformLocation(this.program, "uResolution") : null
    this.uTimeFloat = this.program ? this.gl.getUniformLocation(this.program, "uTimeFloat") : null
    // Only used variables get returned, so this can only check the necessary attribute, not optional universals
    this.loaded = (
      this.program !== null &&
      this.aVertexPosition !== null
    )
    this.vertexBuffer = this.initBuffers()
  }

  getStatus = () => {
    return this.gl.getError()
  }

  render = (t: number) => {
    if(this.aVertexPosition !== null) {
      const gl = this.gl
      gl.clearColor(0,0,0,1)
      // gl.clearDepth(1)
      // gl.enable(gl.DEPTH_TEST)
      // gl.depthFunc(gl.LEQUAL)

      gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT)

      // TODO this might not be safe if canvas is OffscreenCanvas, not sure how it could be
      const resolutionX = (gl.canvas as HTMLCanvasElement).clientWidth
      const resolutionY = (gl.canvas as HTMLCanvasElement).clientHeight

      // TODO go through this, rewrite and shit...just tired...

      // Tell WebGL how to pull out the positions from the position
      // buffer into the vertexPosition attribute.
      {
        const numComponents = 2;  // pull out 2 values per iteration
        const type = gl.FLOAT;    // the data in the buffer is 32bit floats
        const normalize = false;  // don't normalize
        const stride = 0;         // how many bytes to get from one set of values to the next
                                  // 0 = use type and numComponents above
        const offset = 0;         // how many bytes inside the buffer to start from
        gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
        gl.vertexAttribPointer(
            this.aVertexPosition,
            numComponents,
            type,
            normalize,
            stride,
            offset);
        gl.enableVertexAttribArray(
            this.aVertexPosition);
      }

      gl.useProgram(this.program);

      gl.uniform2fv(this.uResolution, [resolutionX, resolutionY])
      gl.uniform1f(this.uTimeFloat, t)

      {
        const offset = 0;
        const vertexCount = 4;
        gl.drawArrays(gl.TRIANGLE_STRIP, offset, vertexCount);
      }
    } else console.log("something didn't bind")
  }

  private initBuffers = () => {
    const vertexBuffer = this.gl.createBuffer()
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, vertexBuffer)
    const vertices = [
      -1,  1,
       1,  1,
      -1, -1,
       1, -1,
    ]
    this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(vertices), this.gl.STATIC_DRAW)
    return vertexBuffer
  }

  // returns null
  private initShaderProgram = () => {
    const vertexShader = this.loadShader(this.gl.VERTEX_SHADER, this.vertex)
    const fragmentShader = this.loadShader(this.gl.FRAGMENT_SHADER, this.fragment)
    if(vertexShader && fragmentShader) {
      const shaderProgram = this.gl.createProgram()
      if(shaderProgram) {
        this.gl.attachShader(shaderProgram, vertexShader)
        this.gl.attachShader(shaderProgram, fragmentShader)
        this.gl.linkProgram(shaderProgram)
        if(this.gl.getProgramParameter(shaderProgram, this.gl.LINK_STATUS)) {
          return shaderProgram
        } // TODO error handling?
        console.log("failed shader")
        return shaderProgram
      } // TODO else handle failed build?
    } else {
      // TODO handle compile failure
      console.log("Shaders failed to compile")
    }
    return null
  }

  // returns null if compile fails
  private loadShader = (type: number, src: string) => {
    const shader = this.gl.createShader(type)
    if(shader) {
      this.gl.shaderSource(shader, src)
      this.gl.compileShader(shader)
      if(this.gl.getShaderParameter(shader, this.gl.COMPILE_STATUS)) {
        return shader
      }
      console.log(this.gl.getShaderInfoLog(shader))
    }
    return null
  }


}
