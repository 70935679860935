import React from 'react'

import Layout from '../components/Layout'
import Sunflare from '../components/webgl/Sunflare/Sunflare'

const WebGLView = () => {
  return (
    <Layout>
      <div>
        <Sunflare />
      </div>
    </Layout>
  )
}

export default WebGLView
