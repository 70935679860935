import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import HomeView from './views/HomeView'
import WebGLView from './views/WebGLView'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/webgl" component={WebGLView} />
        <Route component={HomeView} />
      </Switch>
    </Router>

  )
}

export default App
