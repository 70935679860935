import React from 'react'

import { FaTerminal, FaGithub, FaLinkedin } from 'react-icons/fa'

import 'bulma/css/bulma.css'
import './Layout.css'
import "@fontsource/ubuntu-mono"

interface LayoutProps {
  children: JSX.Element
}

const Layout = ({children}: LayoutProps) => {
  return (
  <>
    <div className="container is-fluid">
      <div className="columns header">
        <div className="column is-hidden-touch is-1 "></div>
        <div className="column is-hidden-tablet title">
          <h6>sambolias</h6>
        </div>
        <div className="column is-hidden-mobile title">
          <h1>sambolias</h1><FaTerminal />
        </div>
      </div>
    </div>

    <div className="columns footer-area align-items-center">
      <div className="column footer-info has-text-left">
        sambolias &copy; 2021
      </div>
      <div className="column footer-center has-text-center">

      </div>
      <div className="column footer-icons has-text-right">
        <a href="https://www.linkedin.com/in/sam-erie/" target="_blank" rel="noreferrer"><FaLinkedin /></a>
        <a href="https://github.com/sambolias" target="_blank" rel="noreferrer"><FaGithub /></a>
      </div>
    </div>

    <div className='main'></div>

    <div className='content-area'>{children}</div>

  </>
  )
}

export default Layout
