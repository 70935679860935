import React from 'react'

import Render2DCanvas from '../libs/Render2DCanvas'
// hardcoded for efficiency...reading files in react is surprisingly complex...
// TODO consider async reader and setting up load
const vertex = `
    attribute vec2 aVertexPosition;

    void main()
    {
      gl_Position = vec4(aVertexPosition, 0., 1.);
    }
  `
const fragment = `
    precision highp float;

    uniform vec2 uResolution;
    uniform float uTimeFloat;

    #define PI 3.14159265359

    float plot(vec2 st)
    {
      return smoothstep(0.12, 0., abs(st.y - st.x));
    }

    float rand( float x)
    {
      return fract(sin(x) * 999.);
    }

    float upTo(float x, float min, float max)
    {
      if(x < max && x > min) return x;
      return 0.;
    }

    float upToo(float x, float y, float max)
    {
      if(x < max) return y;
      return 0.;
    }

    float upFrom(float x, float y, float min)
    {
      if(x > min) return y;
      return 0.;
    }

    float cubicPulse( float c, float w, float x )
    {
      x = abs(x - c);
      if( x>w ) return 0.0;
      x /= w;
      return 1.0 - x*x*(3.0-2.0*x);
    }

    void main()
    {
      vec2 st = gl_FragCoord.xy/uResolution;

      vec3 lColor = vec3(0., 0., 0.);

      for(float i = 1.0; i <= 1.15; i+= 0.009)
      {
        float y = sin(st.x*3. + rand(i) + uTimeFloat);
        vec3 baseColor = vec3(1., rand(i)/2., 0.);
        vec2 pos = vec2(st.y * 2.2 - 1.75 + i, y/(3.+sin(uTimeFloat*(1. + i))) + 0.5);
        float pct = plot(pos);
        lColor += pct * baseColor;
      }

      lColor = mix(vec3(0.,0.,0.),lColor, upToo(st.x, pow(st.x,1.2), .5));
      // lColor = mix(vec3(0.,0.,0.),lColor, upTo(pow(st.x + 0.5,2.), 0., 1.0) - 0.25);

      vec3 rColor = vec3(0., 0., 0.);

      for(float i = 1.0; i <= 1.15; i+= 0.009)
      {
        float y = cos(-st.x*3. + rand(i) + uTimeFloat + PI/2.-.111);
        vec3 baseColor = vec3(1., rand(i)/2., 0.);
        vec2 pos = vec2(st.y * 2.2 - 1.75 + i, y/(3.+sin(uTimeFloat*(1. + i))) + 0.5);
        float pct = plot(pos);
        rColor += pct * baseColor;
      }

      rColor = mix(vec3(0.,0.,0.), rColor, upFrom(st.x, pow(-st.x+1., 1.2), .5));
      // rColor = mix(vec3(0.,0.,0.), rColor, upFrom(st.x + 0.5, cubicPulse(1.0, 0.55, st.x + 0.5), 1.));

      gl_FragColor = vec4(lColor + rColor, 1.);
    }
  `
const Sunflare = () => {
  return (
    <>
    <div className="is-hidden-mobile">
      <Render2DCanvas
        id="testWebGL"
        width={640}
        height={480}
        vertex={vertex}
        fragment={fragment}
      />
    </div>
    <div className="is-hidden-tablet">
      <Render2DCanvas
        id="testWebGL2"
        width={320}
        height={240}
        vertex={vertex}
        fragment={fragment}
      />
    </div>
    </>
  )
}

export default Sunflare
