import React from 'react'
import { Link } from 'react-router-dom'

import Layout from '../components/Layout'

const HomeView = () => {
  return (
    <Layout>
      <div>
        <h3 className="is-size-4">Hello world.</h3>
        <p>
          There isn't much here yet. Learned React and decided I need a new site. More soon to come.
        </p>
        <p>
          Check out the start of my <Link to='/webgl'>WebGL</Link> page.
        </p>
      </div>
    </Layout>
  )
}

export default HomeView
