import React, { useCallback, useState } from 'react'

import { useEffect } from 'react'

import WebGL2DApp from './WebGL2DApp'

interface Render2DCanvasProps {
  id: string
  width: number
  height: number
  vertex: string
  fragment: string
}

const Render2DCanvas = ({ id, height, width, vertex, fragment }: Render2DCanvasProps) => {
  const [timer, setTimer] = useState(0)
  const [status, setStatus] = useState(0)
  const [app, setApp] = useState<null | WebGL2DApp>()

  // ref usage based on example
  // https://css-tricks.com/using-requestanimationframe-with-react-hooks/
  const callbackRef = React.useRef<number>();
  const timeRef = React.useRef<number>(0);

  const animate = useCallback((time: number) => {
    // TODO is there any reason to use dt? could probably use time directly
    const deltaTime = time - timeRef.current;
    setTimer(timer + deltaTime/250)
    timeRef.current = time;
    callbackRef.current = requestAnimationFrame(animate);
  }, [timer])

  React.useEffect(() => {
    callbackRef.current = requestAnimationFrame(animate);
    // cancel animation to clean up after effect
    return () => cancelAnimationFrame(callbackRef.current as number);
  }, [animate]);

  useEffect(() => {
    const el = document.getElementById(id) as HTMLCanvasElement
    const gl = el.getContext("webgl")
    if(gl) {
      const newApp = new WebGL2DApp(gl,vertex,fragment)
      if(newApp.loaded) {
        setApp(newApp)
      }
    }
  }, [id, vertex, fragment])

  useEffect(() => {
    if(app) {
      // on mobile checking gl status seems to keep it alive
      // otherwise experiencing crashes for some reason
      // TODO find out why
      const err = app.getStatus()
      if(err === 0) {
        app.render(timer)
      } else {
        setStatus(err)
      }
    }
  }, [app, timer, setStatus])

  return (
    <>
      { (status === 0)
        ?
          <canvas id={id} height={height} width={width} />
        :
          <p>WebGLRenderingContext failed with error: {status}</p>
      }
    </>
  )
}

export default Render2DCanvas
